//@import 'bootstrap/_variables';

//------- FONTS
$font-family:				  		Arial, Helvetica, sans-serif;
$font:						  		normal 13px/18px $font-family;
$font-custom:                       'Manrope', sans-serif;
//$font-custom:                 	'Roboto Slab', serif;
$font-family-custom:                 normal 13px/18px $font-custom;
$font-icon:                   		"FontAwesome";

$font-size-base:                    13px;

$headings-font-family:              $font-custom;

$comma-separator:                   ",";

//--------- COLORS


$hv-new-blue:					#64B5E2;
$hv-new-purple:					#6E3FCB;
$hv-new-pink:					#EF3B86;
$hv-new-yellow:					#FAEE04;
$hv-new-text-color:				#000000;

$body-text-color:               #505050;
$body-a-hover-color:            #9da7b5;
$base-text-color:               #747474;
$light-text-color:              #9a9a9a;
$white-color:                   #ffffff;

$hv-blue:                       #363445;
$hv-blue-dark:                  #0a0814;

$hv-green:                      #64B5E2;
$hv-green-dark:                 #6E3FCB;
$hv-green-light:				#e5f4ed;

$hv-grey:                       #f6f6f6;
$hv-grey-mid:                   #bcbcbc;
$hv-grey-border:                #e6e6e6;
$hv-grey-dark:                  #919097;



$hv-grey-link-a:                #696969;
$hv-grey-blank:					#bfbebe;

$hv-blue-neon:                  #66cccc;
$hv-red-neon:                   #f66161;

//borders

$light-grey-border:             #e5e5e5;


$dark-green-border:             #00ca74;

$border-radius-btn:             2px;
//bootsrap overwrite
$panel-default-border:          $light-grey-border;
$well-border:                   $light-grey-border;


//sub heading (h2,h3) define
//@include sub-headeing();
//@extend col-md-4
/*
@mixin sub-heading {
	color: $base-heading-color;
	text-transform: uppercase;
	font-family: $font-custom;
	font-weight: 600;
	font-size: 18px;
	line-height: 20px;
	padding: 14px 20px 17px;
	margin: 0 0 20px;
	position: relative;
	border: 1px solid $base-border-color;
	border-bottom: none;
	background: $base-box-bg;
}*/
