@charset "UTF-8";
//LANDING PAGE
body {

    &.login-page {
		background: #323232;
		min-width: 320px;
		min-height: 100vh;
		overflow: auto;
		height: auto;
		//overflow: hidden;
		position: relative;
		display: flex;
		align-items: center;

		// mobile
		@media (max-width: 767.98px) {
			flex-direction: column;
		}

		#full-page {
            display: none;
        }

		.row{
			display: flex;
			flex-wrap: wrap;
		}

		.d-flex{
			display: flex;
		}

		.d-none{
			display: none !important;
		}

		.d-md-block{

			@media (min-width: 768px){
				display: block !important;
			}
		}

		.d-md-none {

			@media (min-width: 768px){
				display: none !important;
			}
		}

		.align-items-center{
			align-items: center;
		}

		> .container{
			/* padding-top: 4rem;
			padding-bottom: 4rem; */
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			padding-top: 5%;
			min-height: 100vh;
			max-height: 1300px;
			width: 100% !important;

			@media (min-width: 1200px){
				max-width: 75vw;
			}

			// mobile
			@media (max-width: 767.98px) {
				padding-top: 0;
				padding-bottom: 0;
				height: 100vh;

				> .row{
					height: 100%;
				}
			}
		}

		#myVideo {
			min-height: 0 !important;
			min-width: 0 !important;
			position: fixed;
		}

		// taller than 16:9
		@media (min-aspect-ratio: 16/9) {
			#myVideo {
				bottom: auto !important;
				height: auto !important;
				left: 0 !important;
				margin-top: calc( -100vw * 9 / 16 / 2 ) !important;
				right: 0 !important;
				top: 50% !important;
				width: 100vw !important;
			}
		}

		// smaller than 16:9
		@media (max-aspect-ratio: 16/9) {
			#myVideo {
				bottom: 0 !important;
				height: 100vh !important;
				left: 50% !important;
				margin-left: calc( -100vh * 16 / 9 / 2 ) !important;
				right: 0 !important;
				top: 0 !important;
				width: auto !important;
			}
		}

		// exactly 16:9
		@media (aspect-ratio: 16/9) {
			#myVideo {
				bottom: 0 !important;
				height: 100vh !important;
				left: 0 !important;
				margin-left: 0 !important;
				margin-top: 0 !important;
				right: 0 !important;
				top: 0 !important;
				width: 100vw !important;
			}
		}

		// mobile
		@media (max-width: 767.98px) {
			#myVideo {
				position: absolute;
				margin: 0 !important;
				height: auto !important;
				width: 100% !important;
				display: block !important;
				bottom: auto !important;
				left: 0 !important;
				top: 100px !important;
			}
		}

		.comment-bubble{
			background-color: rgba(#FFFFFF, .7);
			border-radius: 15px;
			bottom: 20%;
			left: 10%;
			max-width: 310px;
			padding: 30px;
			position: fixed;
			transition: all .5s;
			transition-delay: 750ms;
			opacity: 0;
			visibility: hidden;

			// tablet small
			/* @include media-breakpoint-only(md) {
				top: 50%;
			} */

			&:after{
				content: url("../img/speech_bubble.svg");
				position: absolute;
				right: calc(100% - 20px);
				top: 0;
				transform: rotate(90deg)

				// desktop
				/* @include media-breakpoint-down(xl) {
					bottom: -58px;
				} */
			}

			&.loaded {
				opacity: 1;
				visibility: visible;;
			}

			.slick-dots{
				display: none !important;
			}

			.slick-slide{
				max-width: calc(310px - 60px);
			}

			.comment-carousel-item{
				text-align: left;

				*{
					font-weight: 600;
					font-style: italic;
					font-size: 14px;
					line-height: 24px;
					color:#373446;
				}
			}

			.comment-slider-counter{
				text-align: right;
				margin-right: 1rem;
				font-weight: 700;
				font-style: italic;
				font-size: 14px;
				line-height: 24px;
				color: #8A8A8A;

				span.current{
					color:#373446;
				}
			}

			.slick-button{
				position: absolute;
				bottom: -54px;
				border: none;
				background: none;
				font-size: 14px;
				line-height: 24px;
				outline: none !important;

				i{
					font-weight: 700;
					color: #373446;
				}

				&.slider-next{
					right: -9px;
				}

				&.slider-prev{
					right: 47px;
				}
			}
		}

		.login-form{

			margin-left: auto;

			.content-wrapper{
				//background-color: rgba(#FFFFFF, .85);
				// background-color: #FFFFFF;
				background-color: rgba(255,255,255, .25);
				border-radius: 15px;
				padding: 2.75rem;
				overflow: hidden;
				text-align: center;

				.main-logo{
					background-color: transparent;
				}

				.main-slogan{
					font-size: 25px;
					line-height: 37px;
					font-weight: 800;
					margin-bottom: 1.25rem;
					color: #FFFFFF;
				}

				h4{

					font-size: 14px;
					line-height: 26px;
					font-style: italic;
				}

				.login-content{
					max-height: 1000px;
					opacity: 1;
					transition: all .5s;
					visibility: visible;

					&.hidden-login-content {
						@media (min-width: 768px) {
							max-height: 0;
							opacity: 0;
							transition: all .5s;
							visibility: hidden;
						}
					}

					h1{
						color: $white-color;
						font-size: 16px;
						line-height: 32px;
						font-weight: 700;
						letter-spacing: 2.6px;
						margin-bottom: 2.5rem;
						text-transform: uppercase;
						position: relative;
						display: inline-block;
						width: 100%;
						text-align: center;

						&:before,
						&:after{
							content: "";
							position: absolute;
							width: 100%;
							height: 1px;
							background-color: rgba(#FFFFFF, .5);
							top: 50%;
						}

						&:before{
							left: -85%;
						}

						&:after{
							right: -85%;
						}
					}

					.fos_user_registration_login{
						max-width: 25rem;
						margin: auto;

						p {
							color: $white-color;
						}

						.form-group{

							.input-group{
								display: block;

								.form-control{
									float: none;
								}
							}

							a{
								color: $white-color;
							}
						}

						.checkbox{

							label{
								color: $white-color;
							}
						}

						.btn.btn-register{
							font-size: 13px;
							color: #444444;
							font-weight: 500;

							&:hover{
								color: #9A9A9A;
							}
						}
					}

					.content-bottom{

						.wrapper-bg{
							max-width: 25rem;
							margin: auto;
							display: flex;
							align-items: center;
							justify-content: space-between;
							border-top: 1px solid rgba(#9A9A9A, .5);
							padding-top: 15px;
						}

						@media (max-width: 767.98px) {
							.wrapper-bg {
								max-width: 100%;
								padding-left: 20px;
								padding-right: 20px;

								a {
									color: #444444;
								}
							}
						}

						p,a{
							color: $white-color;
							font-weight: 500;
							text-transform: uppercase;
							font-size: 13px;
							line-height: 32px;
						}

						a{

							&:hover{
								color: #9A9A9A;
							}
						}

						p:last-child{
							display: none;
						}
					}

					label {
						color: $white-color;
					}
				}
			}

			@media (max-width: 767.98px){
				padding: 0;
				height: 100%;
				width: 100%;

				.content-wrapper{
					padding: 2.75rem 1.5rem !important;
					min-height: 100%;
					border-radius: 0;
					display: flex;
					flex-direction: column;
					align-items: stretch;
					justify-content: space-between;

					& > div {
						margin: 0 auto;
						max-width: 450px;
						width: 100%;
					}

					.main-logo{
						height: auto;
						padding: 0;
					}

					.main-slogan{
						font-size: 16px;
						line-height: 26px;
					}

					h4{
						display: none;
					}

					.login-content{

						h1{
							display: none;
						}
					}

					.content-bottom{
						margin-bottom: 0;
						// position: absolute;
						width: 100%;
						left: 0%;
						bottom: 0;
						position: relative;
						margin-top: 50px;

						.wrapper-bg{
							border-top: none !important;

							&:before{
								content: '';
								background: #F5F5F5;
								width: 200%;
								height: 200%;
								display: block;
								position: absolute;
								top: 0;
								left: 50%;
								transform: translateX(-50%);
							}
						}

						*{
							position: relative;
						}

					}
				}
			}

			.btn-register-redirect {
				background-color: $white-color;
				border: unset;
				color: #000000;
			}
		}

		.mobile-login{
			padding: 0 1rem;
			display: flex;
			flex-direction: column;
			margin: auto;

			// mobile
			@media (max-width: 767.98px) {
				min-height: 100vh;
				height: auto !important;
				padding-top: calc( 100vw * 9 / 16 + 100px ) !important;
				justify-content: flex-start !important;
			}

			.logo{
				margin-bottom: 4.125rem;


				@media (max-width: 320px){
					width: 100%;
				}

				// mobile
				@media (max-width: 767.98px) {
					left: 50%;
					margin-left: -138px;
					margin-top: 45px;
					position: absolute;
					top: 0;
					width: 276px;
				}
			}

			.btn{
				margin-bottom: 1rem;
				height: 35px;
				font-size: 11px;
				line-height: 22px;

				&.btn-white {
					background-color: $white-color;
				}

				// mobile
				@media (max-width: 767.98px) {
					&.open-login,
					&.open-register {
						min-width: 80vw !important;
					}
					&.open-login {
						margin-top: 80px !important;
					}
					&.open-register {
						margin-bottom: 80px !important;
					}
				}
			}
		}


		div.footer{
			pointer-events: none;
			position: absolute;
			left: 2.5rem;
			bottom: 2rem;

			// mobile
			@media (max-width: 767.98px) {
				position: static !important;
				height: 30px !important;
				margin-top: -30px !important;
			}

			p{
				color: #ffffff;
				font-weight: 900;
			}
		}
	}
}
