@charset "UTF-8";

html,
body {
	height: 100%;
	// The html and body elements cannot have any padding or margin.
}

body {
	background-color: $hv-grey;
	font-family: $font-custom;
	font-weight:400;
	font-size: $font-size-base;
	color: $body-text-color;

	margin:0;
	padding: 0;
	background-color: $hv-grey;

	&.sonata-cms-editor {
		margin-top: 50px;
		padding-top: 0;

		@media (max-width: 414px) {//ha admin látszik akkor eltakarja a headert
			margin-top: 100px;
		}

		.sonata-bc .navbar {
			margin-bottom: 0;
		}

		.sonata-page-top-bar {

		}
	}
}

a {
	color:#000000;
	@include css3-transition ( all, 0.3s, ease);

	&:hover, &:focus{
		color: $body-a-hover-color;
		text-decoration: none;
	}
	&:active, &:focus {
		outline: none;
	}

}


p{
	color: $light-text-color;
}

.border-left, .border-right{

	border-left: 1px solid $light-grey-border;

}
.wrapper {
	min-height: 1000px;
	overflow: hidden;
	position: relative;
}

.nopads{

	padding: 0;

}

.embed-responsive {
	position: relative;
	display: block;
	height: 0;
	padding: 0;
	overflow: hidden;

	.embed-responsive-item,
	iframe,
	embed,
	object {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		border: 0;
	}

	// Modifier class for 16:9 aspect ratio
	&.embed-responsive-16by9 {
		padding-bottom: 56.25%;
	}

	// Modifier class for 4:3 aspect ratio
	&.embed-responsive-4by3 {
		padding-bottom: 75%;
	}

	&.embed-responsive-1by1 {
		padding-bottom: 100%;
	}
}
//select2 start

.am-wrapper{
	float:left;
	position:relative;
	overflow:hidden;
}
.am-wrapper img{
	position:absolute;
	outline:none;
}

a.media-item-wrapper {
	padding: 0;
	margin: 0;
	position: relative;
	background: #EEEEEE;

	.media-item-overlay {
		position: absolute;
		display: block;
		width: 100%;
		bottom: 0;
		left: 0;
		padding-left: 5px;
		padding-right: 5px;
		padding-bottom: 5px;
		margin: 0px;
		max-width: 100%;
		color: rgba(255,255,255,0.9);
		text-shadow: 0px 0px 4px #000000;

		div.title {
			text-align: right;
			padding: 3px;
			max-width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}

.gallery-thumbs {
	margin-bottom: 30px;

	.embed-responsive > a {
		background-size: cover;
	}

	@media (max-width: 767px) {
		max-width: 480px;
		margin: auto auto 15px auto;
	}
	.gallery-buttons {
		> li {
			padding: 0;
			.btn {
				text-align: center;
				padding: 6px;
				color: $hv-new-blue;
				i, .pe {
					margin: 0;
				}
			}
		}
	}
}

.hv {
	.select2-drop {
		border-radius: 0;
	}
	.select2-drop-active {
		border-color: #aaa #aaa #aaa;
	}

	.select2-container {

		.select2-choice {
			background: #ffffff;
			border: 0 none;
			border-radius: 0;
			height: 36px;
			line-height: 1.42857;
			padding: 8px 12px;

			.select2-arrow b {
				background-position:0 6px;
			}

			.select2-arrow {
				border-radius: 0;
				background: #ffffff;
				border-left: 1px solid transparent;
			}
		}
		&.select2-dropdown-open {

			border-color: #aaa;
			border-radius: 0;

			.select2-choice {
				border-color: #aaa #aaa transparent #aaa;
				.select2-arrow{
					border-left: 1px solid transparent;

				}
			}
		}
		&.select2-container-active{
			.select2-choice {
				@include box-shadow(none);
			}
			.select2-choices {
				@include box-shadow(none);
			}
		}
	}

	.select2-results .select2-highlighted {
		background: $hv-green;
		color: #fff;
	}

	.list-icons {
		.form-control.select2-container {
			margin-left: 10px;
			margin-top: -9px;
		}

		&.ratings{
			.form-control.select2-container {
				margin-top: 0;
			}
		}
	}
	//select2 end
}

//HV end



// background image
.bg-image {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	display: block;

	.bg-image-event-highlight{
		background-image: unset !important;
	}

	img {
		display: none !important;
		height: 0 !important;
		width: 0 !important;
	}

	.bg-image-text {
		display: block !important;
		height: 0 !important;
		overflow: hidden !important;
		text-indent: -20000px !important;
		width: 0 !important;
	}

	// ratios
	&.bg-image-square {
		padding-bottom: 100%;
	}

	// ratios
	&.bg-image-1by1 {
		padding-bottom: 100%;
	}

	&.bg-image-4by3 {
		padding-bottom: 75%;
	}

	&.bg-image-3by2 {
		padding-bottom: 66.66666%;
	}

	&.bg-image-5by3 {
		padding-bottom: 60%;
	}

	&.bg-image-16by9 {
		padding-bottom: 56.25%;
	}

	&.bg-image-3by1 {
		padding-bottom: 33.33333%;
	}

	.bg-image-event-highlight { //2by1
		padding-bottom: 30%;

		@media (max-width: 768px) {
			padding-bottom: 35%;
		}
	}
}

form {

	&.login-form,
	&.fos_user_registration_register {
		label {
			color: $white-color !important;
		}
	}

	&.fos_user_registration_register label {
		a {
			color: #00ca74 !important
		}
	}

	label {
		border: 1px solid transparent;
		font-size: 14px;
		height: 38px;
		line-height: 1.42857;
		padding: 8px 12px;
		width: 100%;
		margin-bottom: 0;

		&[for="fos_user_registration_form_termsAndConditions"] {
			padding-left: 20px;
			position: relative;
			text-align: left;
		}
	}
	.col-left {
		padding-right: 0;

		@media (max-width: 767px) {
			padding-right: 15px;
		}
	}
	.col-right {
		padding-left: 0;

		@media (max-width: 767px) {
			padding-left: 15px;
		}
	}
	&.form-attending{
		label{
			background: none;
			height: auto;
			width: auto;
			padding: 0 12px;
			float: left;
		}
	}

	.col-lock-form-group {
		.col-lock {
			.btn {
				height: 38px;
			}
		}
	}

	@media (max-width: 767px) {
		.col-lock-form-group {
			position: relative;
			.col-lock {
				height: 38px;
				padding: 0;
				position: absolute;
				right: 15px;
				top: 0;
				width: 38px;
				.btn {
					padding: 0;
					width: 38px;
					height: 38px;
				}

			}

			//            .col-lock-input {
			//                padding-right: 52px;
			//
			//            }
		}
	}
}

.form-control {
	border-color: $hv-new-purple;
	border-radius: 6px;
	padding: 8px 12px;
	height: 38px;
	@include box-shadow(none);

	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	&:focus{
		@include box-shadow(none);
		border-color: $light-grey-border;
		@include css3-placeholder(#cccccc);
	}

	&[disabled], &[readonly], fieldset[disabled] .form-control {
		color: #a1a1a1;
	}

	// form control fake to file upload skin
	&-fake {
		height: 38px;
		//line-height: $project-form-control-height;
		overflow: hidden;
		padding-bottom: 0 !important;
		padding-top: 0 !important;
		text-align: left;
		border: 0;
		padding-left: 0;

		* {
			display: inline-block;
			line-height: 1;
			margin: -3px 0 0 0;
			padding: 0;
			vertical-align: middle;
			width: 100%;

			.ie & {
				margin-top: -4px;
			}
		}
	}


}

select.form-control {
	//height: 33px;

}
textarea.form-control{
	max-width: 100%;
}

.btn {
	border-radius: 2px;
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 600;

	.pe{
		&.pe-7s-close-circle,
		&.pe-7s-plus,
		&.pe-7s-angle-down-circle{
			font-size: 24px;
			line-height: 24px;
			margin: 0;
			//transform: rotate(-45deg);
		}
	}
	&.disabled {
		.pe {
			color: $hv-grey-mid;
		}
	}
	&.icon-btn{
		border-radius: 0;
		//width: 30px;
		//height: 30px;
		border: none;
		background: transparent;

		padding: 0;

		.pe{
			margin: 0;
			font-size: 20px;
			line-height: 20px;
			vertical-align: middle;
		}
		&:hover{
			color: $hv-green-dark;
		}
	}

	&.btn-file {
		position: relative;
		overflow: hidden;
		background-color: #e6e6e6;

		input[type=file] {
			position: absolute;
			top: 0;
			right: 0;
			min-width: 100%;
			min-height: 100%;
			font-size: 100px;
			text-align: right;
			//		  filter: alpha(opacity=0);
			//		  opacity: 0;
			@include css3-opacity(0);
			background: red;
			cursor: inherit;
			display: block;
		}
		input[readonly] {
			background-color: white !important;
			cursor: text !important;
		}
	}

	&.more-btn {
		border: medium none;
		line-height: 24px;
		padding: 8px 25px;
		color: #FFF;
		background-color: $hv-new-pink;
		border-radius: 50px !important;
		font-size: 20px;
		font-weight: 300;
		line-height: 24px;
		font-family: "Patrick Hand", sans-serif;
		text-transform: lowercase;
		max-width: 180px;
		position: relative;
		box-shadow: 0px 8px 0px 0px #D9D9D9;

		&:hover{

			background: linear-gradient(90deg, rgba(251,191,65,1) 0%, rgba(91,38,126,1) 100%);

		}

		/*&:after{
			content: "";
			width: 100%;
			height: 100%;
			background-color: #000;
			border-radius: 50px;
			position: absolute;
			top: 20px;
			left: 0;
			z-index: -1;
		}*/
	}

	&.disabled {
		color: #fff;
		cursor: not-allowed;
		background-color: #ddd;
		border-color: transparent;
	}
}

input.btn.disabled {
	color: #fff;
	cursor: not-allowed;
	background-color: #ddd;
	border-color: transparent;
}

.news-item{
	padding: 50px 50px 19px 50px;


	@media (max-width: 480px) {
		padding: 19px;
	}

	.news-heading{
		.panel-title{

			color:$hv-new-pink;
			font-weight: 700;
			margin-bottom: 10px;
		}
	}

	.news-image{
		img{
			@media (max-width: 480px) {
				max-width: 100%;
				margin: 0 auto;
			}
		}
	}

	.news-text{
		font-size: 14px;
		line-height: 22px;

		p{
			color: #000;

		}

		@media (max-width: 767px) {
			padding-top: 15px;
		}
	}

	.btn-block.blank {

		float: left;
		&:hover{

			background-color: transparent;

		}

		@media (max-width: 767px) {
			float: none;
		}
	}
	.widget{
		margin-bottom: 25px;

		@media (max-width: 767px) {
			padding-left: 0;
		}

		@media (max-width: 480px) {
			padding-left: 0;
		}
	}
	.profile-img-holder {

		.profile-img {
			float: right;
			width: 60px;
			border-radius: 50px;

			@media (max-width: 767px) {
				margin-right: 15px;
				float: left;
			}
			img.media-object{
				height: 62px;
				width: 62px;

				@media (max-width: 480px) {
					height: 45px;
					width: 45px;
				}
			}
		}
	}
}

//SIDEBAR NEWS PANEL
.news-panel {
	border-radius: 15px;

	.panel-heading{
		border-radius: 15px 15px 0 0;
	}

	.news-item {
		border: medium none;
		padding: 5px 15px;
		background-color: $white-color;

		&:last-child{
			border-bottom: none;
		}

		&:after{
			content: "";
			display: block;
			border-top: 1px solid $hv-new-pink;
			width: 100%;
			margin-top: 15px;
		}

		.news-heading{
			margin-bottom: 5px;
			font-family: "Manrope", sans-serif;

			.panel-title {
				line-height: 22px;
				margin-bottom: 0;
				color: $hv-new-pink;
				font-weight: 400;
				font-size: 14px;
				font-family: "Manrope";
			}

			.admin-info {
				font-weight: 400;
				padding: 0 10px 0 0 !important;
				color: #000;

				span {
					line-height: 16px;
				}

				&:before{
					content: "";
					background-image: url(../img/calendar.svg);
					width: 13px;
					height: 13px;
					display: inline-block;
					background-repeat: no-repeat;
					background-size: contain;
					margin: 0px 3px -3px 3px;
				}


			}
		}
		.news-text{
			font-size: 13px;
			line-height: 20px;
			color: $base-text-color;

			@media (min-width: 1200px) and (max-width: 1679px) {
				width: 100%;
			}
		}
		.news-image{
			margin-bottom: 15px;

			@media (min-width: 1200px) and (max-width: 1679px) {
				width: 100%;
			}

			img {
				max-width: 100%;
			}
		}
	}
	.profile-img-holder .profile-img {
		float: left;
		margin-right: 15px;
		width: 52px;
		border-radius: 50px;
	}
}

.news-show {

	.list-inline {
		&.list-unstyled {
			&.share {
				margin-top: 30px;
				text-align: right;
			}
		}

		a{
			color: $hv-new-blue;
		}
	}

	.share-block{
		float: right;
	}

	.news-image {
		margin-bottom: 15px;
	}

	.page-header{
		margin: 0;
		border-bottom: 1px solid transparent;
		h1{
			color: #09942d;
			font-size: 28px;
			font-weight: 300;
		}

	}
	.raw-content{
		padding-bottom: 15px;

		p, span{
			color:$light-text-color !important;
			font-size: 14px !important;
			line-height: 22px !important;
		}

		img {
			display: block !important;
			height: auto !important;
			max-width: 100% !important;
			margin: 15px 0;
		}
	}
}

.admin-info{
	color:$light-text-color;

}

.other-event-title{
	font-size: 41px;
	color: $hv-new-purple;
	font-family: "Patrick Hand", sans-serif;

	@media (max-width: 768px) {
		font-size: 20px;
		line-height: 22px;
	}
}

.event-list{
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	justify-content: space-between;
}

.special-event:nth-child(even){

	.event-card{
		position: relative;

		&:before{
			content: "";
			background-color: #D9D9D9;
			border-radius: 15px;
			width: 100%;
			height: 100%;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			transform: rotate(1.5deg);
		}
	}
}

.special-event:nth-child(odd){

	.event-card{
		position: relative;

		&:before{
			content: "";
			background-color: #D9D9D9;
			border-radius: 15px;
			width: 100%;
			height: 100%;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			transform: rotate(-1.5deg);
		}
	}

	.panel-thumbnail {
		&:before{
			background-image: url(../img/dots2.svg) !important;
			background-position: bottom right !important;
		}
	}
}

.event-card {
		.panel-body-outer {
		.panel-body {
			h4 {
				margin-bottom: 13px;
				margin-top: 3px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				line-height: 24px;
			}

			p {
				color: $light-text-color;
				margin: 0 0 15px;
				min-height: 60px;
			}
		}

		.pe-ul{
			opacity: 0;
		}

		&.open {
			.pe-ul {
				opacity: 1;
			}
		}
	}

	.btn-success {
		background-color: $hv-new-yellow;
		border: unset;
		font-family: "Patrick Hand", sans-serif;
		font-size: 25px;
		text-transform: none;
		color: #000;
		font-weight: 400;
		border-radius: 50px;
		padding: 10px 42px 12px 42px;
		position: absolute;
		bottom: -25px;
		right: 50px;
		box-shadow: 0px 8px 0px 0px #D9D9D9;

		@media (max-width: 768px) {
			display: none;
		}

		&:after {
			content: "";
			background-image: url(../img/submenu-icon.svg);
			width: 12px;
			height: 12px;
			display: inline-block;
			background-repeat: no-repeat;
			background-size: contain;
			margin: 0px 2px 0px 8px
		}

		&:hover{
			background-color: $hv-new-pink;
		}
	}
}


.normal-event {
	padding: 20px 13px;
	overflow: hidden;
	background-color: #FFF;
	border-radius: 15px;

	@media (min-width: 768px) {
		width: 47% !important;
	}

	.panel{
		background-color:#FFF;
		margin-bottom: 0 !important;
	}

	.panel-title{
		font-size: 10px !important;
		font-family: "Manrope", sans-serif !important;
		font-weight: 400 !important;
		color: #000 !important;
	}

	.panel-days-left{
		display: none;
	}

	.panel-thumbnail{
		margin-bottom: unset !important;

		.bg-image{
			padding-bottom: 170px;
			border-radius: 15px;
			cursor: pointer;
		}
	}

	.btn-success{
		position: relative;
		right: unset;
		bottom: unset;
		box-shadow: unset;
		padding: 5px 42px 7px 42px;
		margin-bottom: 5px;
	}

	.event-name{
		a{
			font-size: 25px;
			font-family: "Bebas Neue", sans-serif;
			color: #2086C6 !important;

			@media (max-width: 768px) {
				font-size: 18px;
				line-height: 18px;
			}
		}
	}

	.pe-7s-users{
		&:before{
			content: "" !important;
			background-image: url(../img/users-normal-event.svg);
			width: 20px;
			height: 18px;
			display: inline-block;
			background-repeat: no-repeat;
			background-size: contain;
			margin: 0px 3px -3px 3px;
		}
	}

	.panel-body{
		li{
			font-weight: 700 !important;
		}

		p{
			color: #000 !important;
		}
	}

	.panel-footer{
		ul{
			border-top: unset !important;
			display: flex;
			justify-content: center;

			li{
				border-top: unset !important;
				padding: 18px 40px !important;

				a{
					padding: unset !important;
					float: right !important;
				}
			}
		}

		.comments{
			a{
				font-weight: 700;

				span{
					margin: 5px 0 0 5px;
					color: $hv-new-blue;
				}
			}

			&:before{
				width: 30px;
				height: 30px;

				@media (max-width: 768px) {
					width: 25px;
					height: 25px;
				}
			}
		}

		.pe-7s-like{
			&:before{
				content: "";
				background-image: url(../img/like-icon.svg);
				width: 30px;
				height: 30px;
				display: inline-block;
				background-repeat: no-repeat;
				background-size: contain;
				margin: -1px 3px -3px 3px;

				@media (max-width: 768px) {
					width: 25px;
					height: 25px;
				}
			}
		}

		.pe-7f-like{
			&:before{
				content: "";
				background-image: url(../img/liked.svg);
				width: 30px;
				height: 30px;
				display: inline-block;
				background-repeat: no-repeat;
				background-size: contain;
				margin: -1px 3px -3px 3px;

				@media (max-width: 768px) {
					width: 25px;
					height: 25px;
				}
			}
		}
	}

	@media (min-width: 1380px) {
		//		min-width: 340px;
		//		max-width: 50%;
	}

	@media (max-width: 1699px) {
		//		min-width: 340px;
		width: 50%;
	}

	@media (max-width: 767px) {
		width: 100%;
	}

	@media (max-width: 480px) {
		min-width: 275px;
	}
}

.special-event:nth-child(even){
	.event-card {
		.panel-body{
			align-items: start;
		}

		@media (min-width: 769px){
			&:after{
				content: "";
				right: -20px;
				left: unset !important;
			}
		}

		.btn-success{
			right: unset;
			left: 50px;
		}

		.event-name{
			a{
				margin-left: -30px;
			}
		}
	}
}

.special-event{

	.main-news-card{
		&:after{
			content: "";
			background-image: url(../img/havervagy_badge.svg) !important;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
			position: absolute;
			width: 98px;
			height: 98px;
			left: -20px;
			bottom: 130px;
		}
	}

	.event-card {

		&:after{
			content: "";
			background-image: url(../img/kulso.png);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
			position: absolute;
			width: 98px;
			height: 98px;
			left: -20px;
			bottom: 130px;

			@media (max-width: 768px) {
				bottom: unset;
				top: -25px;
				left: 5px;
				width: 65px;
				height: 65px;
			}
		}

		.panel-body{
			background-color: transparent !important;
			display: flex;
			flex-direction: column;
			align-items: end;
			justify-content: center;
			padding-bottom: 70px;

			@media (max-width: 768px) {
				padding-bottom: 0px;
			}
		}

		.panel-title{
			color: $hv-new-yellow !important;
			font-family: "Patrick Hand", sans-serif;
			font-weight: 400;
			font-size: 24px !important;
			line-height: 32.5px;
			background-color: $hv-new-purple;
			border-radius: 50px;
			float: right;
			padding: 8px 20px 8px 20px;
			margin-bottom: -15px;
			z-index: 1;

			@media (max-width: 768px) {
				font-size: 16px !important;
				line-height: 1.1em;
			}
		}

		.event-name a{
			color: $white-color !important;
			font-family: "Bebas Neue", sans-serif;
			font-weight: 400;
			font-size: 40px !important;
			line-height: 48px;
			background-color: #2086C6;
			border-radius: 50px;
			float: right;
			padding: 12px 35px 10px 35px;
			margin-right: -30px;
			max-width: 550px;
			position: relative;

			@media (max-width: 768px) {
				font-size: 18px !important;
				line-height: 20px;
				margin-bottom: -25px;
				padding: 12px 20px 10px 20px;
			}

			&:before{
				content: "";
				background-color: #D9D9D9;
				border-radius: 50px;
				width: 100%;
				height: 100%;
				display: block;
				position: absolute;
				top: 10px;
				left: 0;
				z-index: -1;
			}
		}

		&.special-event,
		&.event-card-highlighted {
			.panel {
				cursor: pointer;
				border-color: $dark-green-border;
				background: $hv-green-light;

				.panel-heading,
				.panel-footer,
				.panel-body-outer {
					background: $hv-green;
				}

				.panel-heading {
					.pe, .panel-title, .panel-title span {
						color: $white-color;
					}
				}

				.panel-thumbnail {
					margin-bottom: 60px;

					&:before{
						content: "";
						background-image: url(../img/dots.svg);
						background-repeat: no-repeat;
						background-size: 50%;
						background-position: bottom left;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}

				//hide things
				.panel-body-outer {
					bottom: 0;
					max-height: 60px;

					.panel-body {
						p, ul, a.btn {
							display: none;
						}
						h4 {
							margin-bottom: 0;
							margin-top: 0;
							line-height: 30px;
							a {
								color: $white-color;

								&:hover, &:focus {
									color: $hv-green-light;
								}
							}
						}
					}
				}

				.panel-body {
					border: 0;
				}

				//			.panel-thumbnail {
				//				max-height: 300px;
				//			}

				.panel-footer {
					.pe-ul {
						&.pe-panel-footer {
							border-top: 1px solid $dark-green-border;

							> li{
								border-right: 1px solid $dark-green-border;
								border-top: 1px solid $dark-green-border;

								&.active{
									border-top: 1px solid $white-color;
								}
							}
						}
					}
				}
			}
		}

		.panel-days-left{
			position: absolute;
			right: 10px;
			top: -10px;
			background: linear-gradient(180deg, rgba(228, 57, 133, 1) 0%, rgba(101, 41, 126, 1) 100%);
			color: $white-color;
			width: 105px;
			height: 105px;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			border-radius: 50%;

			span{
				display: flex;
				flex-direction: column;

				@media (max-width: 768px) {
					font-size: 10px;
        			line-height: 10px;
				}
			}

			.time-elapsed-number{
				font-family: "Protest Riot", sans-serif;
				font-size: 50px;
				line-height: 48px;

				@media (max-width: 768px) {
					font-size: 15px;
        			line-height: 15px;
				}
			}

			@media (max-width: 768px) {
				width: 60px;
				height: 60px;
				top: -20px;
			}
		}

		&.own-event {
			.panel {
				border: 3px solid $hv-green-dark;
			}

			&:after{
				background-image: url(../img/havervagy_badge.svg) !important;
				}
			}
		}

		//	&.open {
		//
		//		.panel {
		//
		//			.panel-body-outer {
		//				bottom: 53px;
		//			}
		//		}
		//	}
	}



.special-event:nth-child(even){
	.event-name{
		a{
			background-color: $hv-new-pink;
		}
	}
}

.special-event:nth-child(3n+3){
	.event-name{
		a{
			background-color: #FBC042;
		}
	}
}

.event-status{

	.circle-icon {
		float: right;
		margin-top: 3px;
	}

	dt{ width: 20px;
		float: left;

	}
	dd{
		margin-left: 20px;

	}
	.form-control{
		height: 32px;
		padding: 5px 12px;
		width: 80px;
		float: left;
		margin-top: -5px;

		@media (max-width: 768px) {

			margin-left: 15px;
			margin-top: 5px;

		}
	}
}

.rate{
	padding-right: 10px;
}

.rate-nr{
	font-size: 24px;
	color:$hv-green;
	font-weight: 700;
}

.panel {
	border: unset !important;
	border-radius: 15px;
	margin-bottom: 30px;
	position: relative;
	@include box-shadow(none);

	.panel-thumbnail{
		margin-bottom: 53px;
		border-radius: 15px;;
		.img-responsive {
			width: 100%;
		}
	}

	.panel-body-outer{
		position: absolute;
		overflow: hidden;
		max-height: 170px;
		z-index: 1;
		bottom: -70px;
		background: $white-color;
		width: 100%;
		transition: all .5s;

		li{
			color: #FFF !important;
			opacity: 0;
		}

		&.open {
			bottom:53px;

			li{
				color: $hv-new-blue !important;
				opacity: 1;
			}
		}
	}

	.pe {
		font-size: 16px;
		line-height: 16px;
		margin-right: 0.5em;
		//vertical-align: middle;

		&.pull-left {
			margin-right: 0.5em;
		}
		&.pe-7s-plus{
			font-size: 24px;
			line-height: 24px;
		}

	}

	.panel-heading {
		//border-bottom: 1px solid #e5e5e5;
		border-radius: 15px 15px 0 0;

		.pe-7s-date, .pe-7f-users, .pe-7s-help1{
			margin-top: 8px !important;
		}

	}
	.memories-accordion & {
		.panel-heading {
			border-bottom: 0;
			border-radius: 0 !important;

		}
	}

	.panel-heading + .panel-body{
		box-shadow: 0px 8px 0px 0px #D9D9D9;
		border-radius: 0 0 15px 15px;
		padding-bottom: 40px;
	}

	.panel-body {
		position: relative;
		background-color: $white-color;
		background-color: #ffffff;
		&.organizer, &.attending-status {
			border-top: 0;
		}

		.panel-heading {
			padding-top: 0;
			//border-bottom: 1px solid #e5e5e5;
		}

		.dl-horizontal{
			dt{
				text-align: left;
			}
			dd{
				margin-bottom: 10px;
				span{
					float: left;
					padding: 15px 0 0 15px;
				}

			}
		}
		&.gallery{
			border-top:1px solid #e6e6e6;

			.list-inline {
				margin-left: 0;

				> li {
					//display: inline-block;
					padding: 15px;
					position: relative;
					@media (max-width: 480px) {
						padding: 5px;


					}
					//							&:first-child {
					//								padding-left: 25px;
					//							}
				}
			}
			.fancybox {
				.pe {
					bottom: 60px;
					margin-right: 0;
					left:25px;

					&.pe-7s-check{

					}
					&.pe-7s-trash{

					}
				}

			}

		}

		.fancybox {
			.img {
				border: 3px solid transparent;
				@include transition ( border-color 0.3s ease );
			}
			&:hover .img{
				border-color: $hv-green-dark;
			}

			.pe {
				bottom: 10px;
				color: #ffffff;
				left: 15px;
				position: absolute;
				margin-right: 0;

				&.pe-7s-check{

				}
				&.pe-7s-trash{

				}
			}
		}

		&.tabs{
			padding: 0;
			box-shadow: 0px 8px 0px 0px #D9D9D9;
			border-radius: 15px;
			padding-bottom: 40px;
		}
		//       h4{
		//            font-size:  16px;
		//            margin-bottom: 15px;
		//            margin-top: 5px;
		//        }
		h5{
			font-size:  12px;
		}

	}//end panel body

	.tab-content {
		padding: 15px;

		.user-card {
			margin-bottom: 15px;

			@media (max-width: $screen-xs-max) {
				float: none;
				margin: auto;
				max-width: 320px;
			}

			.user-card-title {
				font-size: 13px;
				line-height: 20px;
				margin: 0;
				padding: 10px 0;

				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
		.tab-pane {

			.list-inline {
				margin-left: 0;

				> li {
					//display: inline-block;
					//padding: 15px;
					position: relative;

					//					@media (max-width: 480px) {
					//						padding: 5px;
					//					}
					//					&:first-child {
					//						padding-left: 25px;
					//					}

				}
			}
		}
	}
	.list-view{

		.tab-content {
			padding: 0;

			.tab-pane{

				div:first-child  {
					//padding-top: 0;
				}
				div:last-child  {
					border-bottom: 0;
				}
				li{
					float: left;

					&.icon-link a{

						float: left;

						&:last-child {
							padding-left: 10px;
							padding-right: 3px;
						}

					}

				}

			}
		}

	}

	.panel-footer{
		padding: 0;
		border-top: none;
		background: none;
		background: $white-color;
		position: relative;
		z-index: 2;
		height: 53px;
	}

	.panel-heading {

		height: 80px;
		padding-top: 22px;
		background: $white-color;


		&.lg {
			padding-top: 18px;

			.panel-title {
				color: $hv-new-purple;
				float: none;
				font-family: "Patrick Hand", cursive;
				font-size: 26px;
				font-weight: 400;
				line-height: 38px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				display: flex;
				align-items: center;
				gap: 10px;
				margin-bottom: 20px;
			}

			i {
				font-size: 24px;
				float: left;
				line-height: 24px;
			}
		}

		&.underlined {
			border-bottom: 1px solid $light-grey-border;
			margin: 0 0 15px;
			padding: 15px 0 5px;
		}

		&.tab-heading{
			//border-bottom: 1px solid $light-grey-border;
			position: relative;

			.btn {
				margin: 0;
				position: absolute;
				right: 15px;
				top: 15px;
			}

		}

		.panel-buttons {
			float: right;
			@media (min-width: $screen-sm-min) {
				margin-top: -20px;
			}
		}

		.panel-title {
			float: left;
			font-size: 12px;
			font-weight: 300;
			line-height: 16px;
			text-transform: none;
			color: $base-text-color;

			span{
				margin-left: 5px;
				color: $light-text-color;
			}


		}
		&.underlined{
			border-bottom: 1px solid $light-grey-border;
			height: 45px;
			margin: 0 0 15px;
			padding: 5px 0 0;
		}
	}

	.pe-ul {
		float: left;
		margin-left: 10px;
		margin-top: 5px;
		padding-left: 0;

		> li {
			display: inline;
			padding-left: 10px;
			position: relative;
		}

		&.pe-panel-footer{
			float: left;
			padding: 0;
			margin: 0;
			height: 53px;//+1 px  a magassága a border top miatt! miért nem megy...
			border-top: 1px solid $light-grey-border;
			width: 100%;

			> li{
				border-right: 1px solid $light-grey-border;
				border-top: 1px solid $light-grey-border;
				float: left;
				padding: 0;
				margin-top: -1px;

				&.active{
					border-top: 1px solid $white-color;
				}
				&:last-child{
					border-right: none;
					padding: 16px 0px 18px 40px !important;
				}
				&:first-child{
					padding: 18px 40px 18px 0px !important;
				}

				i{
					margin: 0;
					font-size: 20px;
				}
				a{
					padding: 18px;
					text-align: center;
					float: left;
					color: #444444;
					text-decoration: none;

					span{
						//float: right;
						margin: 0 0 0 5px;
					}

					&:hover{
						color:$body-a-hover-color;
					}
				}

				&.more{
					padding: 15px 18px 19px 18px;
					text-align: center;
					float: left;
					color: #444444;
					text-decoration: none;
					cursor: pointer;

					span{
						float: right;
						margin: -2px 0 0 5px;
					}

					&:hover{
						color:$body-a-hover-color;
					}
				}
				&.comments a{
					padding: 14px 18px 17px 18px;

					span{
						float: right;
						margin: 2px 0 0 5px;

					}
				}
			}
		}
	}

	//	.fos_comment_thread_commentable {
	//		margin-top: -25px;
	//	}

	&.rating-panel{

		.media{
			padding: 5px 15px 10px;
			background-color: $white-color;
			margin-top: 0 !important;

		}
		.media-body {
			border-top: 1px solid #e5e5e5;
			float: left;
			margin-top: 10px;
			padding-top: 10px;
			width: 100%;

			.btn {
				//margin-top: -10px;
			}
		}
		.event-date{
			font-size: 11px;
			line-height: 16px;
			padding-top: 5px;
		}
	}

	.attending-status {

		.panel-heading.lg {
			border-bottom: 1px solid #ddd;
			height: 45px;
			margin-bottom: 15px;
			padding-left: 0;
			padding-top: 7px;
		}
	}
}

.panel.profile-show {
	@media (max-width: $screen-xs-max) {
		.panel-heading.tab-heading {
			display: inline-block;
			position: relative;
			height: auto;

			.panel-title {
				display: inline-block;
				margin-bottom: 10px;
				width: 100%;
			}

			.btn {
				float: left !important;
				margin: 0;
				position: relative;
				right: auto;
				top: auto;
			}
		}
	}
}

//.panel .pe-ul.pe-panel-footer > li.more {
//	&:focus {
//		.panel .panel-body-outer {
//			bottom:53px;
//		}
//	}
//}

.nav-tabs {
	background: none repeat scroll 0 0 #f6f6f6;
	//border-top: 1px solid $light-grey-border;
	border-top: 0;
	height: auto;

	.pe {
		margin-left: 0.5em;
	}

	li {
		@media (max-width: 767px) {
			width: 100%;
		}
		a:hover{
			border-bottom: 1px solid #e5e5e5;
		}

	}
}

.list-icons{
	margin-bottom: 15px;
	li{
		padding: 15px;
		border-right: 1px solid $light-grey-border;
		&:last-child{
			border-right: none;

		}
		&.event-users-attending{
			min-width:80px;
		}
		&.users-commented{
			min-width:65px;
		}

		&.status{
			padding: 15px 5px;
		}

		&.pin{
			padding: 15px 5px;
		}

		&.users, &.comments{

			padding: 15px 5px;
			min-width: 60px;
			text-align: center;

			@media (max-width: 1024px) {
				min-width: auto;
			}
		}

		&.icon-link{
			padding: 0;
			a{
				padding: 15px;
			}
		}

	}
	.pe{
		font-size: 19px;
		line-height: 19px;
	}

	&.vertical{

	}

	&.ratings{
		margin: 0;
		li{
			padding: 10px;
			float: left;

			&.rating {
				//border-right: none;
				//padding-bottom: 1px;
				padding-left: 5px;

				&.select2-form{
					padding: 0;
				}

				.select2-container .select2-choice > .select2-chosen {
					margin-right: 10px;
				}
				.btn{
					margin-left: 0;
					margin-top: -1px;
				}

				.form-group{
					float: left;
					margin-bottom: 0;
					margin-left: 10px;
					margin-top: -5px;


					select.form-control {
						float: left;
						margin: 0;
						height: 28px;
						padding: 2px 15px 2px 10px;
						width: auto;
					}
				}
				.pe {
					float: left;
					margin-right: 0;
					//margin-top: 4px;
				}

				.form-group {
					float: left;
					margin-bottom: 0;
					margin-left: 10px;
				}
				.btn {
					margin-bottom: 0;
					margin-left: 10px;
					margin-top: -1px;
				}

				@media (max-width:320px) {
					margin-top: 5px;
				}

			}
			&:first-child {
				padding-left: 0;
			}
			&:last-child{
				border-right: none;
				padding-right: 0;
				//border-left: 1px solid $light-grey-border;

			}

			&.users, &.comments{
				min-width: auto;
			}
		}

		.pe{
			margin-right: 0;
		}
	}
}

.list-view{

	.list-icons{
		float: right;
		margin-bottom: 0;
	}
	.info {
		padding-right: 0;
		padding-top: 15px;
	}
	.list-view-item {
		float: left;
		width: 100%;
		padding-top: 0;
		border-bottom:1px solid $hv-new-pink;

		.media {
			padding: 15px;
		}

		.list-view-item-heading a{
			color: $hv-new-pink;
			font-weight: 700;
		}

	}

	.media-body p {
		margin: 0;
	}
	.media-body p.list-view-item-heading{
		color:$body-text-color;
		margin: 5px 0;
		font-size: 14px;
	}
}

.well{
	border-radius: 15px;
	border: unset !important;

	&.well-sm{
		padding: 0;
	}
	&.news-item{
		margin-bottom: 20px;
		border-bottom: 0;

		&:last-child{
			border: 1px solid #e6e6e6;
			margin-bottom: 30px;
		}

	}
	.btn-block{

		text-indent: -666666;
		font-size: 24px;
		line-height: 24px;

		&.blank{
			border-color: transparent;
			color: $hv-new-blue;
		}
		span{
			font-size: 16px;
			line-height: 16px;
			text-transform: none;
			font-weight: 300;
		}
		&:hover{
			background-color: transparent;
			color:$hv-grey-dark;
		}
	}
}

.circle-icon {
	width: 10px;
	height: 10px;
	background: transparent;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
	border-radius: 10px;
	display: inline-block;
	margin-right: 0.5em;

	&.green{
		background: #00ca74;
	}

	&.grey{
		background: #d5d8dc;
	}
	&.red{
		background: #f66161;
	}
	&.yellow {
		background: #F4FA58;
	}
	&.blue {
		background: #428bca;
	}
}
//////////////// SIDEBAR + TOPBAR  /////////////////////////////

.top-bar {
	background-image: url(../img/header-bg.svg);
	background-repeat: no-repeat;
	background-size: cover;
	height: 74px;
	position: relative;
	width: 100%;

	&:before {
		animation: 3s ease 0s normal forwards 1 load;
		background-color: #60c6cf;
		content: "";
		height: 2px;
		left: 0;
		position: fixed;
		top: 0;
		transform: translate3d(-100%, 0px, 0px);
		width: 100%;
		z-index: -1;
	}

	@media (max-width: 480px) {
		position: absolute;
		z-index: 999;
	}

	@media (max-width: 768px) {
		background-image: url(../img/mobile-header-bg.png);
		background-size: cover;
	}
}

.slogen{
	color: $white-color;
	text-align: center;
	font-family: "Patrick Hand", sans-serif;
	font-size: 20px;
	position: absolute;
    top: 20px;
    right: 25%;

	@media (max-width: 1024px) {
		display: none;
	}
}

.sidebar{
	background: none repeat scroll 0 0 #f9f9f9;
	float: left;
	//    margin-bottom: -99999px;
	//    padding-bottom: 99999px;
	position: relative;
	margin-top: 50px;

	@media (min-width: 481px) and (max-width: 995px) {
	}

	@media (min-width: 481px) and (max-width: 995px) {

		@include transition (width 0.3s ease);
		width: 69px;
		position: absolute;
		z-index: 10;

		&:hover{
			//@include transition (width 0.3s ease);
			width: 280px;
		}
	}

	&.left-sidebar{
		@media (min-width: 481px) {
			background-color: transparent !important;
		}
	}

	@media (max-width: 480px) {
		position: absolute;
		z-index: 999;
		margin-bottom: 0;
		padding-bottom: 0;
		top: 25px;
		width: 100%;
		&:hover{
			width: 100%;
		}
	}
}

.main-nav {
	list-style: none outside none;
	margin: 0;
	padding: 0;

	@media (max-width: 995px) {
		background-color: $hv-new-blue;
		border-radius: 15px;
	}

	@media (min-width: 481px) and (max-width: 995px){
		padding: 15px 15px 15px 0px;
	}

	&.open{
		padding: 20px;
	}

	.menu-home-icon{
		.main-nav-link{
			&:before{
				content: "";
				background-image: url(../img/menu-home-icon.svg);
				width: 20px;
				min-width: 20px;
				height: 20px;
				display: inline-block;
				background-repeat: no-repeat;
				background-size: contain;
				margin: 0px 15px 0px 20px;
			}
		}
	}

	.menu-profile-icon{
		.main-nav-link{
			&:before{
				content: "";
				background-image: url(../img/menu-profile-icon.svg);
				width: 20px;
				min-width: 20px;
				height: 20px;
				display: inline-block;
				background-repeat: no-repeat;
				background-size: contain;
				margin: 0px 15px 0px 20px;
			}
		}
	}

	.menu-events-icon{
		.main-nav-link{
			&:before{
				content: "";
				background-image: url(../img/menu-events-icon.svg);
				width: 20px;
				min-width: 20px;
				height: 20px;
				display: inline-block;
				background-repeat: no-repeat;
				background-size: contain;
				margin: 0px 15px 0px 20px;
			}
		}
	}

	.menu-news-icon{
		.main-nav-link{
			&:before{
				content: "";
				background-image: url(../img/menu-news-icon.svg);
				width: 20px;
				min-width: 20px;
				height: 20px;
				display: inline-block;
				background-repeat: no-repeat;
				background-size: contain;
				margin: 0px 15px 0px 20px;
			}
		}
	}

	.menu-help-icon{
		.main-nav-link{
			&:before{
				content: "";
				background-image: url(../img/menu-help-icon.svg);
				width: 20px;
				min-width: 20px;
				height: 20px;
				display: inline-block;
				background-repeat: no-repeat;
				background-size: contain;
				margin: 0px 15px 0px 20px;
			}
		}
	}

	.menu-about-icon{
		.main-nav-link{
			&:before{
				content: "";
				background-image: url(../img/menu-about-icon.svg);
				width: 20px;
				min-width: 20px;
				height: 20px;
				display: inline-block;
				background-repeat: no-repeat;
				background-size: contain;
				margin: 0px 15px 0px 20px;
			}
		}
	}

	.menu-video-icon{
		.main-nav-link{
			&:before{
				content: "";
				background-image: url(../img/menu-video-icon.svg);
				width: 20px;
				min-width: 20px;
				height: 20px;
				display: inline-block;
				background-repeat: no-repeat;
				background-size: contain;
				margin: 0px 15px 0px 20px;
			}
		}
	}

	li {
		margin-bottom: 10px;
		//transition: all 0.3s ease 0s;
		//        @include css3-transition(all, 0.3s, ease);

		.main-nav-link {
			align-items: center;
			background: $hv-new-blue;
			border-radius: 0 50px 50px 0;
			color: $white-color;
			display: flex;
			font-family: 'Patrick Hand', sans-serif;
			font-size: 22px;
			height: 47px;
			letter-spacing: 0.5px;
			max-height: 47px;
			min-width: 236px;
			line-height: 30px;
			margin-top: 5px;
			overflow: hidden;
			padding-right: 40px;
			//text-overflow: ellipsis;
			white-space: nowrap;
			//transition: all 0.3s ease 0s;
			//@include transition(none);
			@include css3-transition(all, 0.3s, ease);

			//            &:after {
			//                color: #bcbcbc;
			//                //content: "";
			//                font-family: "Pe-icon-7-stroke" !important;
			//                font-size: 22px;
			//                position: absolute;
			//                right: 10px;
			//            }

			.main-nav-icon {
				align-items: center;
				background: transparent;
				display: none;
				height: 20px;
				justify-content: center;
				text-align: center;
				color: $white-color;
				//transition: all 0.3s ease 0s;
				@include css3-transition(all, 0.3s, ease);
				width: 40px;
				float: left;
				padding: 0;

				i {
					font-size: 20px;
					vertical-align: middle;
					//@include css3-transition(all, 0.3s, ease);
				}
			}

			//            &:hover {
			//                color: inherit;
			//                text-decoration: none;
			//
			//                .main-nav-icon{
			//                    box-shadow: 0 -1px 0 $hv-green-dark inset;
			//                }
			//            }
		}

		@media screen and (max-width: 995px){
			.main-nav-link{
				min-width: 67px !important;
				gap: 30px;

				.main-nav-icon{
					padding: 30px;
				}
			}
		}

		@media screen and (max-width: 480px) {
			.main-nav-link{
				border-radius: 50px;
			}


		}

		&.main-nav-active {
			.main-nav-link{
				background: rgb(251,191,65);
				background: linear-gradient(90deg, rgba(251,191,65,1) 0%, rgba(91,38,126,1) 100%);
				box-shadow: 0px 6px 0px 0px #D9D9D9;
			}

			.main-nav-icon {
				background: transparent;
				color: #fff;
			}
		}


		//active állapot

		.main-nav-link {
			&:hover {
				color: #fff;
				text-decoration: none;
				box-shadow: 0px 6px 0px 0px #D9D9D9;
				background: rgb(251,191,65);
				background: linear-gradient(90deg, rgba(251,191,65,1) 0%, rgba(91,38,126,1) 100%);

				.main-nav-icon {
					color: #fff;
					background: transparent;
					//                    i {
					//                        color: #fff;
					//                    }
				}
			}

		}

		//összes többi
		&:not(.main-nav-active) {
			.main-nav-link {
				&:hover {
					background: rgb(251,191,65);
					background: linear-gradient(90deg, rgba(251,191,65,1) 0%, rgba(91,38,126,1) 100%);
					color: #fff;

					.main-nav-icon {
						color: #fff;
						background: transparent;

						//                        i {
						//                            color: #fff;
						//                        }

						//                        &:after {
						//                            color: #fff;
						//                        }
					}
				}
			}
		}

		//submenu
		&.main-nav-collapsible {
			height: auto;

			&:hover {

				.main-nav-submenu {
					max-height: 500px;
				}

				.main-nav-link {
					&:after {
						//@include css3-opacity(0);
						content: "\e682";//angle up
						color: $white-color;
					}
				}
			}

			.main-nav-link {
				&:after {
					font-family: 'Pe-icon-7-stroke';
					content: "\e688"; //angle down
					color: $white-color;
					position: absolute;
					right: 15px;
					font-size: 27px;
					font-weight: 800;
					//@include css3-opacity(1);
					//@include rotate(180deg);
				}

				&:hover {
					&:after {
						//@include css3-opacity(0);
						content: "\e682";//angle up
						color: $white-color;
					}
				}
			}

			&.main-nav-open {
				.main-nav-submenu {
					max-height: 500px;
				}
			}

		}

	}


}


//.main-nav-collapsible.main-nav-open .main-nav-submenu,
//.main-nav-collapsible:hover .main-nav-submenu {
//    max-height: 500px;
//}
//.main-nav-collapsible.main-nav-open .main-nav-link:after,
//.main-nav-collapsible:hover .main-nav-link:after {
//    @include rotate(180deg);
//}
.main-nav-submenu {
	max-height: 0;
	overflow: hidden;
	//transition: max-height 0.5s ease-in-out 0s;
	@include css3-transition(max-height, 0.5s, ease);
	list-style: none;
	margin: 0;
	padding: 0;
}
.main-nav-submenu i {
	align-items: center;
	justify-content: center;
	font-size: 22px;
	display: none;
	height: 50px;
	left: 0;
	padding: 0;
	position: absolute;
	text-align: center;
	top: 0;
	width: 40px;
}

.main-nav-submenu li span{
	&:before{
		content: "";
		background-image: url(../img/submenu-icon.svg);
		width: 10px;
		height: 10px;
		display: inline-block;
		background-repeat: no-repeat;
		background-size: contain;
		margin: 0px 2px 0px 0px;

		@media (min-width: 480px) and (max-width: 995px) {
			margin: 0px 30px 0px -10px !important;
		}
	}
}
.main-nav-submenu li a {
	border-radius: 0 50px 50px 0;
	color: $hv-new-text-color;
	display: block;
	line-height: 54px;
	padding-left: 0;
	text-decoration: none;
	position: relative;
	text-align: left;
	font-family: "Patrick Hand", sans-serif;
	font-size: 20px;
}

.main-nav-submenu span {
	align-items: center;
	justify-content: start;
	display: flex;
	padding-left: 40px;
	text-align: left;
	width: 100%;
}

.main-nav-submenu li{
	margin-bottom: unset;
}

.main-nav-submenu li a:hover {
	background: rgb(251,191,65);
	background: linear-gradient(90deg, rgba(251,191,65,1) 0%, rgba(91,38,126,1) 100%);
	color: #fff;
	padding-left: 0;
}
.main-nav-badge {
	margin-left: 5px;
}

@media (min-width: 0px) and (max-width: 995px) {

	.main-nav-link {
		position: relative;
	}
	.main-nav-link:after {
		display: block;
	}
	.main-nav-badge {
		position: absolute;
		right: 10px;
		top: 15px;
	}

	.main-nav-submenu i {
		display: none;
		height: 54px;
		left: 0;
		line-height: 54px;
		padding: 0;
		position: absolute;
		text-align: center;
		top: 0;
		width: 68px;
	}
	.main-nav-submenu span {
		display: flex;
        text-align: left;
        min-width: 400px;
	}

	.main-nav-submenu li a {
		line-height: 54px;
		padding-left: 0;
		position: relative;
		text-align: center;

		@media (max-width: 480px) {
			border-radius: 50px;
		}
	}

	.main-nav-submenu li a:hover {
		background: rgb(251,191,65);
		background: linear-gradient(90deg, rgba(251,191,65,1) 0%, rgba(91,38,126,1) 100%);
		color: #fff;
		padding-left: 0;

		i {
			//display: none;
		}
		span {
			//display: inline-block;
		}
	}

}

@media (max-width: 480px) {
	.main-nav {
		max-height: 0;
		overflow: hidden;
		border-radius: 15px 1px 15px 15px;
        max-width: 80%;
        margin: auto;
		//transition: max-height 0.5s ease 0s;
		transition: all .5s !important;
	}
	.main-nav.open {
		max-height: 1000px;
	}
	.main-nav-badge {
		position: relative;
		right: auto;
		top: auto;
	}
	.main-header-date {
		display: none;
	}
	//    .main-nav-submenu i {
	//        display: none;
	//    }
	//    .main-nav-submenu span {
	//        display: block;
	//    }
	//    .main-nav-submenu li a {
	//        padding-left: 110px;
	//        text-align: left;
	//    }
}

.mobile-nav {
	display: none;
	height: 74px;
	line-height: 74px;
	text-align: center;
	//transition: background-color 0s ease 0s, all 0.3s ease 0s;
	@include css3-transition(all, 0.3s, ease);
	vertical-align: top;
	width: 74px;

	.hamburger-icon{
		&:before{
			content: "";
			background-image: url(../img/hamburger-icon.svg);
			width: 40px;
			height: 40px;
			display: inline-block;
			background-repeat: no-repeat;
			background-size: contain;
			margin: 25px 10px -5px 3px;
		}
	}

	@media (max-width: 480px) {
		display: inline-block;
		float: right;
		padding: 0;
		position: relative;
		z-index: 2;
	}
}

.main-logo {
	color: #fff;
	display: inline-block;
	font-family: 'Open Sans', sans-serif;
	font-weight: 700;
	font-size: 24px;
	line-height: 74px;
	padding-left: 18px;
	padding-right: 26px;
	position: relative;
	z-index: 2;

	img{
		max-width: 300px;
	}

	span {
		color: #9a9a9a;
		font-family: 'Open Sans', sans-serif;
		font-size: 18px;
	}
	@media (max-width: 767px) {
		padding-left: 20px;

	}
	@media (max-width: 530px) {
		padding-left: 0;
		padding-right: 0;
	}

	@media (max-width: 768px) {
		height: 74px;
		padding-top: 10px;
		.img-responsive {
			max-width: 172px;
		}
	}
}

.main-search {
	//border-left: 1px solid #323232;
	height: 36px;
	left: 280px;
	padding-left: 52px;
	position: absolute;
	top: 20px;

	i {
		color: #ffffff;
		font-size: 22px;
		font-weight: bold;
	}
	input {
		background-color: transparent;
		border: none;
		outline: none;
		color: #ffffff;
		font-size: 14px;
		height: 36px;
		line-height: 36px;
		width: 280px;
		@include css3-opacity(1);
		@include css3-placeholder(#ffffff);

		&:focus{
			outline: none;
			color: #ffffff;
			@include css3-placeholder(#4cda9d);
		}
	}
	label {
		color: #ffffff;
		cursor: pointer;
		font-size: 22px;
		left: 18px;
		position: absolute;
		top: 6px;
	}

	//    input:focus::-moz-placeholder {
	//        //opacity: 0.3;
	//        @include css3-opacity(0.3);
	//        //transition: opacity 0.3s ease 0s;
	//        @include css3-transition(opacity, 0.3s, ease);
	//    }

	@media (max-width: 767px) {
		display: none;
	}
}

.profile {
	color: #9a9a9a;
	padding-right: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;

	@media (min-width: 0px) and (max-width: 995px) {
		padding-right: 0;

		.profile-name {
			display: none;

			i{
				&:before{
					color: $hv-new-pink;
				}
			}
		}
		.profile-img {
			margin-right: 0;
			float: left;
		}
	}
	@media (max-width: 530px) {

		li:not(:first-child) {
			display: none;
		}
		li ul li {
			display: block !important;
		}
	}
	@media (max-width: 320px) {
		//display: none;
		padding: 0;
		margin: 0;
	}

	li {
		display: inline-block;
		position: relative;
		vertical-align: middle;

		&.user-menu{
			background: transparent;
			@include css3-transition(all, 0.5s, ease);

			@media (max-width: 320px) {
				width: 74px;
			}
		}

	}

	i {
		font-size: 24px;
		vertical-align: middle;

		&.pe-7s-angle-down {
			font-weight: bold;
		}
	}
	a {
		color: #ffffff;
		display: inline-block;
		height: 74px;
		line-height: 74px;
		padding: 0;
		text-decoration: none;

		//transition: background-color 0.3s ease 0s;
		@include css3-transition(all, 0.5s, ease);
		vertical-align: middle;

		.profile-name {
			font-size: 14px;
			color: white;
			@include css3-opacity(1);
			@include css3-transition(opacity, 0.5s, ease);

			span {
				font-weight: 700;
			}

			i{
				font-size: 18px;

				&:before{
					color: $hv-new-pink;
					margin-left: 8px;
				}
			}

		}

		.profile-img {
			height: 40px;
			margin: 17px 16px 0 17px;
			width: 40px;
			float: left;

			@media (max-width: 320px) {
				float: none !important;
				height: 40px;
				margin: 17px auto;
				width: 40px;
			}
		}

		&:hover {
			.profile-name {
				@include css3-opacity(0.7);
				span {
				}
			}

		}
		&.dropdown-toggle {
			padding: 0 30px 0 0;
			margin-right: 30px;

			//			&:focus{
			//				background: $hv-blue;
			//
			//			}

			.media-object {
				height: 40px;
				width: 40px;
			}
		}

		@media (max-width: 320px) {
			width: 74px;
			&.dropdown-toggle {
				padding: 0;
			}
		}
	}

	.profile-user {
		color: #9a9a9a;
		font-size: 14px;
		line-height: 72px;

		&:hover {
			color: inherit;
		}
		i {
			font-size: 18px;
			margin-left: 10px;
			vertical-align: middle;
		}
		span {

			font-family: 'Open Sans', sans-serif;
			font-weight: 700;
		}
	}

	.dropdown-menu {
		background-color: $hv-new-blue;
		border: 0 none;
		border-radius: 15px 0 15px 15px;
		@include box-shadow(none);
		margin: 0;
		padding: 10px 19px 15px 19px;
		min-width: 180px;
		right: 34px !important;
		top: calc(100% - 10px);
		box-shadow: 0px 8px 0px 0px #D9D9D9;

		a {
			color: $white-color;
			display: inline-block;
			height: auto;
			padding: 10px 0;
			width: 100%;

			&:hover {
				background-color: rgba(255, 255, 255, 0.05);
				color: white;
			}
		}
		li {
			display: block;
		}
		i {
			margin-right: 5px;
		}
	}

	.profile-higlighted a {
		color: white;
	}
	.profile-badge {
		position: absolute;
		right: 6px;
		top: 18px;
	}

}
.badge-red {
	background-color: #f35958;
}
.rounded-image {
	border-radius: 50%;
	overflow: hidden;
}


/////////////////// CONTENT  + FOOTER////////////////////////

.wrapper > .content {
	margin-left: 281px;
	overflow: auto;
	padding: 43px 30px 63px 30px;
	max-width: 1920px;
	min-height: 100vh;

	&.noleftsidebar {
		margin-left: 0px;
	}

	@media (min-width: 1921px) {
		margin: auto;
	}

	@media (min-width: 0px) and (max-width: 995px) {
		margin-left: 71px;
		padding-right: 60px;
	}
	@media (max-width: 480px) {
		margin-left: 0;
		margin-top: 72px;
		padding-left: 23px;
		padding-right: 23px;
	}
}

.main-header-title {
	//border-right: 1px solid $hv-grey-border;
	color: $hv-new-purple;
	display: flex;
	align-items: center;
	gap: 10px;
	float: left;
	font-family: 'Patrick Hand', sans-serif;
	padding: 0 10px 0 0;
	margin: 0;
	text-transform: none;
	vertical-align: middle;
	font-weight: 400;
	//line-height: 55px;
	//font-size: 41px;
	font-size: 20px;
	line-height: 28px;

	a {
		color: $hv-new-purple;
		display: inline-block;

		&:hover {
			color: #000000;
		}

		&:after {
			content: '/';
			padding: 0 5px;
			margin-left: 5px;
			font-size: 14px;
			font-weight: bold;
		}

		&:last-child {
			&:after {
				content: '';
				padding: 0;
			}
		}
	}

	small {
		color: #9a9a9a;
		font-family: 'Open Sans', sans-serif;
		font-size: 14px;
		text-transform: none;
	}
	i {
		display: inline-block;
		font-size: 18px;
		margin-right: 6px;
		margin-top: -3px;
		vertical-align: middle;
	}
}

.main-header-breadcrumb {
	display: inline-block;
	float: left;
	list-style: none outside none;
	vertical-align: middle;
	margin: 3px 0 0;
	padding: 0;


	li {
		float: left;

		&.active a {
			color: #444;
		}
	}
	a {
		color: #9a9a9a;
		font-size: 12px;
		margin-left: 20px;
		margin-right: 12px;
	}

	@media (max-width: 767px) {

		display: none;
	}
}

.main-header-date {
	color: $hv-new-blue;
	display: block;
	float: right;
	font-family: "Manrope", sans-serif;
	font-size: 14px;
	//margin-top: 1px;
	text-transform: uppercase;
	//vertical-align: middle;

	span {
		margin-left: 10px;
		margin-right: 0;
		line-height: 24px;
		display: inline-block;

		.pe-7s-angle-down-circle {
			display: none;
		}
	}

	.icon {
		font-size: 21px;
		line-height: 24px;
		vertical-align: middle;
	}

	@media (max-width: 1200px) {
		span, i:first-child {
			display: none;
		}
	}
	@media (max-width: 1024px) {
		span, i:first-child {
			display: none;
		}
	}
	@media (max-width: 480px) {
		display: none;
	}
}

.widget {
	margin-top: 30px;
	border-radius: 15px;

	.widget__config {
		float: right;
		margin-right: 0px;
		margin-top: 0px;

		a {
			color: #9a9a9a;
			display: inline-block;
			font-size: 14px;
			text-decoration: none;
			vertical-align: middle;

			&:hover {
				color: #444;
			}
		}
		a .pe-7s-close {
			font-size: 1.5em;
		}
	}
	.media{
		padding: 15px;

		.btn{
			margin-left: 10px;
		}

		&:last-child{
			border-bottom:none;
		}
	}

	.media:not(.accordion-toggle):not(.main-media){
		&:after{
			content: "";
			display: block;
			border-top: 1px solid $hv-new-pink;
			width: 100%;
			margin-top: 35px;
		}
	}

	.list-view{

		.media{
			border-bottom:none;
		}

	}

	.media-heading{
		font-size: 13px;
		font-weight: 700;
		font-family: "Manrope", sans-serif;

		.media-heading-title, a{
			color: $hv-new-pink;
		}
	}

	.ratings{
		margin-top: 5px;
		li{
			padding: 0px 10px !important;
			font-size: 10px;
			border-right: 1px solid $hv-new-pink;
		}

		li:first-child{
			padding-left: 0 !important;
		}
	}

	img.media-object{
		width: 52px;
		height: 52px;
		border-radius: 50px;
	}
}

.main-footer {
	font-size: 14px;
	background-image: url(../img/footer-bg.svg);
	background-repeat: no-repeat;
	background-position: center right;
	background-size: cover;
	padding: 10px;
	position: relative;
	width: 100%;
	display: flex;
    align-items: center;
    gap: 20px;

	p {
		color: $white-color;
		margin: 0 !important;
	}

	@media (max-width: 768px) {
		background-image: url(../img/footer-mobile-bg.png);
		background-position: 50%;
	}

	@media (min-width: 0px) and (max-width: 995px) {

		width: 100%;
	}
}

.footer-main-logo{
	margin-left: auto;

	img{
		max-width: 172px;
	}
}

.back-top {
	color: $white-color;
	display: inline-block;
	font-size: 40px;
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}
}


#fos_comment_thread{

	.fos_comment_comment_show {
		border-bottom: 1px solid $light-grey-border;
		padding: 15px 0;

		.fos_comment_comment_metas{
			margin-right: 15px;
			//max-width: 75px;

			img{
				width: 60px;
				height: 60px;
			}

			h4.fos_comment_comment_authorname{
				font-size: 13px;
			}
			p.fos_comment_comment_body{
				font-size: 14px;
			}

		}
		.fos_comment_submit{
			padding-top: 15px;

		}
		.fos_comment_comment_edit_show_form {
			margin: 0 10px;
		}
		h4.fos_comment_comment_authorname{
			font-size: 13px;
		}
		p.fos_comment_comment_body{
			font-size: 14px;
		}
	}

	.fos_comment_comment_form_holder {

		padding: 15px 0;

		.fos_comment_submit {
			padding-right: 0;
			text-align: right;

			@media (max-width:767px) {
				padding-top: 15px;
			}

		}

		.new-comment{

			.pe{
				font-size: 64px;
				line-height: 64px;
			}
		}
		.comment-field{
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.ingredients{
	h4{

		font-size: 14px;
		color: #b9b9b9;
		margin: 10px 0 20px 15px;

	}

	label{

		margin-top: 7px;
		background: none;
		span{
			color:$light-text-color;
			font-weight: 400;
		}

	}

	.control-label {
		text-align: left;
	}
	.progress{
		margin-bottom: 0;
		margin-top: 13px;
		border-radius: 8px;
		height: 8px;

		@media (max-width: 480px) {
			display: none;
		}
	}
	.ingredients-header{

		@media (max-width: 1280px) {

			h4{font-size: 12px; }

		}
		@media (max-width: 767px) {
			display: none;
		}
	}
}

.profile-edit {
	background: transparent;

	.tab-content {
		background: #ffffff;
		padding: 30px 15px;
		border-radius: 15px;

		.tab-pane{
			display: none;
		}

		.active{
			display: block;
		}
	}

	.nav {
		&.nav-tabs {
			border-top: 0;
			li {

			}
		}
	}

	form{

		label{

			background: #6E3FCB;
			color: #FFF;
			border-radius: 6px 0 0 6px;
		}

		.form-control{
			border-color: #6E3FCB;
			border-radius: 0 6px 6px 0;
		}

		.col-sm-4 select.form-control{
			border-radius: 6px;
		}

		.required{
			&:after{
				color: $white-color;
			}
		}

		.form-group{

			&.date-of-birth{
				label{
					padding: 8px 0 8px 12px;
				}
			}
		}
	}
}


//SWITCH
.sw {
	display: none;
}
.switch, .switch2 {
	background-color: $light-grey-border;
	border-radius: 24px;
	cursor: pointer;
	display: inline-block;
	position: relative;
	//transition: background 250ms ease 100ms;
	@include css3-transition(background, 0.3s, ease);
	vertical-align: text-bottom;
}
.switch.switch--dark, .switch2.switch--dark {
	background-color: $hv-grey;
}
.widget__content .switch, .widget__content .switch2 {
	display: block;
	margin: 20px auto;
}
.switch {
	height: 25px;
	width: 62px;

	span {
		font-size: 12px;
		font-weight: 400;
		position: absolute;
		right: 40px;
		top: 3px;
		width: 120px;
	}
}
.switch2 {
	height: 10px;
	width: 25px;
}
.widget__content .switch2 {
	margin: 31px auto;
}
.switch:before, .switch2:before {
	color: #9a9a9a;
	content: "ki";
	font-family: "Open Sans",sans-serif;
	font-size: 10px;
	line-height: 24px;
	position: absolute;
	right: 11px;
	text-transform: uppercase;
}
.switch2:before {
	line-height: 10px;
	right: -25px;
}
.switch:after, .switch2:after {
	background-color: white;
	border-radius: 50%;
	content: "";
	height: 23px;
	left: 1px;
	position: absolute;
	top: 1px;
	//transition: all 150ms linear 0s;
	@include css3-transition(all, 0.2s, ease);
	width: 23px;
}
.switch--dark:after {
	background-color: $hv-green;
}
.switch2:after {
	border: 1px solid $light-grey-border;
	left: -11px;
	top: -8px;
}
.switch.switch--header:before {
	content: "Be";
	text-transform: none;
}
input:checked + .switch, input:checked + .switch2 {
	background: none repeat scroll 0 0 #b1e5e5;
}
input:checked + .switch--dark:after {
	background-color: #f35958;
}
input:checked + .switch--dark {
	background: none repeat scroll 0 0 $hv-grey;
}
input:checked + .switch--violet {
	background: none repeat scroll 0 0 #989cff;
}
input:checked + .switch--orange {
	background: none repeat scroll 0 0 #ff9c4b;
}
input:checked + .switch--green {
	background: none repeat scroll 0 0 #55c69e;
}
input:checked + .switch--red {
	background: none repeat scroll 0 0 #f35958;
}
input:checked + .switch:after {
	left: 38px;
}
input:checked + .switch2:after {
	border-color: #b1e5e5;
	left: 12px;
}
input:checked + .switch2.switch--green:after {
	border-color: #55c69e;
}
input:checked + .switch2.switch--green:before {
	color: #55c69e;
}
input:checked + .switch2.switch--violet:after {
	border-color: #989cff;
}
input:checked + .switch2.switch--violet:before {
	color: #989cff;
}
input:checked + .switch2.switch--orange:after {
	border-color: #ff9c4b;
}
input:checked + .switch2.switch--orange:before {
	color: #ff9c4b;
}
input:checked + .switch2.switch--red:after {
	border-color: #f35958;
}
input:checked + .switch2.switch--red:before {
	color: #f35958;
}
input:checked + .switch:before {
	color: white;
	content: "on";
	left: 14px;
	right: auto;
}
input:checked + .switch.switch--header:before {
	content: "Ki";
	left: 12px;
	text-transform: none;
}
input:checked + .switch--dark:before {
	color: #9a9a9a;
}
input:checked + .switch2:before {
	color: #b1e5e5;
	content: "on";
	left: -25px;
	right: auto;
}


.single{
	display: table; width: 100%; height: 100%;
	font-size: 36px;
	color: #000000;
	position: relative;
	z-index: 9999;
	line-height: 48px;

	@media (max-width: 480px) {
		font-size: 18px;
		line-height: 24px;
	}
}

.file-upload {
	border: 1px solid $light-grey-border;
	padding: 5px;
}

.organizer {
	dt{
		width: 100px;
		&.organizer-info{
			padding: 15px 0 0;
		}
	}

	dl dd{
		margin-left: 80px;

		.profile-img{

			width: 60px;

		}

	}
	.list-icons {
		display: inline-block;
		margin: 0 0 15px;
		width: 100%;
		> li{
			float: left;

			&.rating {
				padding-left: 5px;
				padding-right: 0;

				.pe {
					float: left;
				}
				span {
					float: left;
				}

				.form-group{

					//					float: left;
					display: inline-block;
					margin: -15px 0 0 15px;

					button{
						//						margin-top: -4px;
						//						margin-left: 5px;
						//						float: left;
						display: inline-block;
						margin-left: 5px;
						margin-top: 0;

					}

					.form-control {
						@media (max-width: $screen-xs-max) {
							display: inline-block;
						}

						&.select2-container {
							float: left;
							margin-left: 10px;
							margin-top: -9px;
						}
					}

				}

			}
		}
	}

}
.edit-event{
	padding-top: 0;

}
.memories-accordion {

	padding: 0;
	border: none;

	.panel-group {
		margin-bottom: 0;

		.panel + .panel {
			margin-top: 0;
		}
	}

	.panel-default {
		border: none;
		border-bottom:1px solid #efefef;

		> .panel-heading + .panel-collapse > .panel-body {
			border-top-color: transparent;
			border-top: 0;
			padding: 0;
		}

		.panel-heading {
			cursor: pointer;
			padding: 0;
			height: 80px;

			.media{

				padding: 15px;

				.event-date {
					font-size: 11px;
					line-height: 16px;
					padding-top: 5px;

					@media (max-width: 320px) {

						span {
							display: none;
						}

					}
				}
			}

			&:before {
				width: 0;
				height: 0;
				border-left: 16px solid transparent;
				border-right: 16px solid transparent;
				border-top: 16px solid #ffffff;
				content: " ";
				left: 75%;
				top: 80px;
				margin-left: -16px;
				pointer-events: none;
				position: absolute;
				z-index: 1;

			}
			//			.accordion-toggle:after {
			//				// symbol for "opening" panels
			//				font-family:'Pe-icon-7-stroke';
			//				content:"\e683";
			//				height: 50px;
			//				float: right;
			//				color: inherit;
			//				font-size: 24px;
			//				position: absolute;
			//				right: 15px;
			//				top: 15px;
			//				color: $hv-grey-blank;
			//				border-left: 1px solid $light-grey-border;
			//				padding-left: 10px;
			//				padding-top: 10px;
			//			}
			//			&.collapsed .accordion-toggle:after {
			//				// symbol for "collapsed" panels
			//				content:"\e689";
			//			}

			.circle-icon{
				margin: 0 0 0 10px;

			}
		}

		.panel-heading[data-toggle="collapse"] {
			&:after {
				content: "";
				background-image: url(../img/down.svg);
				background-repeat: no-repeat;
				background-size: contain;
				position: absolute;
				right: 15px;
				top: 15px;
				width: 20px;
				height: 20px;
			}

			&.collapsed {
				&:after {
					content:"";
					background-image: url(../img/up.svg);
				}
			}

		}

		.table-memories{

			td.memory-img{
				background-color: $white-color;
				img{
					width: 100%;
				}
			}
			td.memory-card{
				background: $hv-green;
				color: #ffffff;
				width:25%;
				cursor: default;
				&.bordered{
					border-right: 1px solid #ffffff;
					border-bottom: 1px solid #ffffff;
					&.right{
						border-right: 1px solid #ffffff;
						border-bottom: none;
					}
				}

			}
			span{
				float: left;
				width: 100%;


				&.align-left{
					text-align: left;
					padding: 10px;
				}

				&.align-right{
					text-align: right;
					padding: 10px;
				}

				&.align-right, &.align-left{
					@media (max-width: 1680px) {
						padding: 0 5px;

					}

					@media (max-width: 1024px) {
						padding: 10px;
					}

					@media (max-width: 767px) {

						padding: 5px;
					}
					@media (max-width: 480px) {
						padding: 0 5px;
						text-align: center;
					}
					@media (max-width: 320px) {
						padding: 0 5px;
						text-align: center;
					}

				}
			}
			i{
				text-align: center;
				width: 100%;
				font-size: 24px;
				margin: 10px 0;

				@media (max-width: 1860px) {
					margin:0;
					font-size: 20px;
				}
				@media (max-width: 1024px) {
					font-size: 36px;
					line-height: 36px;
					margin: 15px 0;

				}

				@media (max-width: 767px) {
					font-size: 22px;
					line-height: 22px;
					margin: 10px 0;
				}
				@media (max-width: 480px) {
					margin: 5px 0;
					font-size: 20px;
					line-height: 20px;
				}

			}
		}

		.flex-memories {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: stretch;

			.flex-item {

			}
		}

		.panel-collapse{
		}
	}
}

.required {
	&:after {
		color: red;
		content: "*";
	}

	> input#fos_user_registration_form_termsAndConditions {
		left: 0;
		margin: 0;
		position: absolute;
		top: 11px;
	}
}

//admin overrides

.sonata-bc {
	.btn, button {
		//		@include border-radius (2px);
		//		font-size: 12px;
		//		font-weight: 600;
		//		text-transform: uppercase;
		@include transition (opacity 0.5s ease);
		&:hover, &:focus {
			background-position: 0 0;
			//@include opacity(0.8);
			@include css3-opacity(0.8);
		}
		i {
			padding: 0;
		}
	}
}
.ui-dialog {
	&.sonata-bc {
		//position: absolute!important;
		//height: auto!important;
		padding-bottom: 40px;

		@media (max-width: 767px) {
			position: absolute!important;
			height: auto!important;
			width: 100%!important;

			.ui-dialog-content {
				overflow: hidden;
			}
		}
		form {
			.box {
				z-index: 1005;
				position: relative;
				cursor: default;
			}
		}
	}
}

img {
	text-indent: -9999px;
	line-height: normal;
}

.pagination > li {
	@media (max-width: 480px) {
		display: inline-block;
		&:first-child,
		&:last-child {
			width: 100%;
			display: block;
			margin-bottom: 10px;
			> a,
			> span {
				display: inline-block;
				float: none;
				position: relative;
				text-align: center;
			}
		}
		&:last-child {
			margin-bottom: 0;
			margin-top: 10px;
		}
	}
}

.other-title-wrapper{
	display: flex;
	gap: 10px;
	padding: 15px 0;
	align-items: center;
	width: 100%;

	@media (max-width: 768px) {
		padding: 5px 0 0 0;
	}
}

//alert info box on home
.alert-event {

	p {
		padding-left: 38px;
		position: relative;

		.icon{
			font-size: 26px;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}

}


.more-btn-wrapper {
    background-color: $white-color;
    display: block;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 25px 0 40px 0;
	border-radius: 0 0 15px 15px;
	box-shadow: 0px 8px 0px 0px #D9D9D9;
}

.ratings-title{
	&:before{
		content: "";
		background-image: url(../img/star.svg);
		width: 28px;
		height: 28px;
		display: block;
		background-repeat: no-repeat;
		background-size: contain;
	}
}

.news-title{
	&:before{
		content: "";
		background-image: url(../img/news-icon.svg);
		width: 28px;
		height: 28px;
		display: block;
		background-repeat: no-repeat;
		background-size: contain;
	}
}

.memories-title{
	&:before{
		content: "";
		background-image: url(../img/memories.svg);
		width: 28px;
		height: 28px;
		display: block;
		background-repeat: no-repeat;
		background-size: contain;
	}
}

.home-icon{
	&:before{
		content: "";
		background-image: url(../img/home-icon.svg);
		width: 28px;
		height: 28px;
		display: block;
		background-repeat: no-repeat;
		background-size: contain;
	}
}

.event-date{
	&:before{
		content: "";
		background-image: url(../img/time.svg);
		width: 13px;
		height: 13px;
		display: inline-block;
		background-repeat: no-repeat;
		background-size: contain;
		margin: 0px 3px -3px 3px;
	}
}

.time{
	color: #000;

	&:before{
		content: "";
		background-image: url(../img/time.svg);
		width: 13px;
		height: 13px;
		display: inline-block;
		background-repeat: no-repeat;
		background-size: contain;
		margin: 0px 3px -3px 3px;
	}
}

.users{
	&:before{
		content: "";
		background-image: url(../img/users.svg);
		width: 13px;
		height: 13px;
		display: inline-block;
		background-repeat: no-repeat;
		background-size: contain;
		margin: 0px 3px -3px 3px;
	}
}

.comments{
	&:before{
		content: "";
		background-image: url(../img/comment.svg);
		width: 13px;
		height: 13px;
		display: inline-block;
		background-repeat: no-repeat;
		background-size: contain;
		margin: 0px 3px -3px 3px;
	}
}

.comments-panel-title{
	&:before{
		content: "";
		background-image: url(../img/comment.svg);
		width: 20px;
		height: 20px;
		display: inline-block;
		background-repeat: no-repeat;
		background-size: contain;
		margin: 0px 3px -3px 3px;
	}
}

.rating{
	&:before{
		content: "";
		background-image: url(../img/rating.svg);
		width: 13px;
		height: 13px;
		display: inline-block;
		background-repeat: no-repeat;
		background-size: contain;
		margin: 0px 3px -3px 3px;
	}
}

.belso-icon{
	&:before{
		content: "";
		background-image: url(../img/belso-icon.svg);
		width: 28px;
		height: 28px;
		display: inline-block;
		background-repeat: no-repeat;
		background-size: contain;
		margin: 0px 3px -3px 3px;
	}
}

.szervezkedj-icon{
	&:before{
		content: "";
		background-image: url(../img/belso-icon.svg);
		width: 28px;
		height: 28px;
		display: inline-block;
		background-repeat: no-repeat;
		background-size: contain;
		margin: 0px 3px -3px 3px;
	}
}

.more-icon-wrapper{
	&:before{
		content: "";
		background-image: url(../img/more-icon.svg);
		width: 30px;
		height: 30px;
		display: inline-block;
		background-repeat: no-repeat;
		background-size: contain;
		margin: 0px 3px -3px 3px;

		@media (max-width: 768px) {
			width: 25px;
			height: 25px;
		}
	}
}

.edit-profile{
	&:before{
		content: "";
		background-image: url(../img/profile.svg);
		width: 20px;
		height: 20px;
		display: inline-block;
		background-repeat: no-repeat;
		background-size: contain;
		margin: 0px 10px -5px 3px;
	}
}

.logout{
	&:before{
		content: "";
		background-image: url(../img/logout.svg);
		width: 20px;
		height: 20px;
		display: inline-block;
		background-repeat: no-repeat;
		background-size: contain;
		margin: 0px 10px -5px 3px;
	}
}

.memory-icon-wrapper{
	text-align: center;

	&:before{
		content: "";
		width: 18px;
		height: 18px;
		display: inline-block;
		background-repeat: no-repeat;
		background-size: contain;
		margin: 0px 3px -3px 3px;
	}
}

.memory-icon-wrapper.star{
	&:before{
		background-image: url(../img/white-star.svg);
	}
}

.memory-icon-wrapper.camera{
	&:before{
		background-image: url(../img/white-camera.svg);
	}
}

.memory-icon-wrapper.users{
	&:before{
		background-image: url(../img/white-users.svg);
	}
}

.memory-icon-wrapper.comments{
	&:before{
		background-image: url(../img/white-comments.svg);
	}
}
