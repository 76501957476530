@charset "UTF-8";

//@import "bootstrap";
// Core variables and mixins
@import "bootstrap/variables";
@import "bootstrap/mixins";

// Reset and dependencies
@import "bootstrap/normalize";
@import "bootstrap/print";
//@import "bootstrap/glyphicons";

// Core CSS
@import "bootstrap/scaffolding";
@import "bootstrap/type";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";

// Components
@import "bootstrap/component-animations";
@import "bootstrap/dropdowns";
@import "bootstrap/button-groups";
@import "bootstrap/input-groups";
@import "bootstrap/navs";
@import "bootstrap/navbar";
@import "bootstrap/breadcrumbs";
@import "bootstrap/pagination";
@import "bootstrap/pager";
@import "bootstrap/labels";
@import "bootstrap/badges";
@import "bootstrap/jumbotron";
@import "bootstrap/thumbnails";
@import "bootstrap/alerts";
@import "bootstrap/progress-bars";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/panels";
@import "bootstrap/responsive-embed";
@import "bootstrap/wells";
@import "bootstrap/close";

// Components w/ JavaScript
@import "bootstrap/modals";
@import "bootstrap/tooltip";
@import "bootstrap/popovers";
@import "bootstrap/carousel";

// Utility classes
@import "bootstrap/utilities";
@import "bootstrap/responsive-utilities";
//end bootstrap

// Make sure the charset is set appropriately
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Protest+Riot&display=swap');


@import "theme_variables";

// Mixins
@import "project-mixins";
@import "project-mixins-css3";

// HV LAYOUT
@import "hv-layout";

@import "fancybox-custom";

//login page base
@import "login-page-base";
//new login page
//@import "login-page-draggable";

@import "./pe-icon-7-filled/css/helper.css";
@import "./pe-icon-7-filled/css/pe-icon-7-filled.css";
@import "./pe-icon-7-stroke/css/pe-icon-7-stroke.css";
@import "font-awesome.css";
@import "slick-carousel/slick/slick.scss";
@import "@fancyapps/fancybox/dist/jquery.fancybox.css";
